import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { BoxProps, Container, Flex, useTheme, VStack } from '@chakra-ui/react';
import { CAMActiveMovementDirection } from '@egzotech/exo-session/features/cam';
import { computed, ReadonlySignal } from 'helpers/signal';
import {
  CalibrationFlow,
  exerciseActionTracker,
  GeneratedExerciseDefinition,
  isCAMExerciseDefinition,
  isSpecificGeneratedExerciseDefinition,
  MotorPlacement,
  SensorsName,
  SettingsParameterId,
} from 'libs/exo-session-manager/core';
import { SensorForcePlugin } from 'libs/exo-session-manager/core/global/SensorForcePlugin';
import { useCalibrationFlowState, useDevice, useProgramSelection } from 'libs/exo-session-manager/react';

import {
  ExtensionForceSensorIcon,
  HeelForceToMaxSensorIcon,
  HeelForceToMinSensorIcon,
  KneeForceToMaxSensorIcon,
  KneeForceToMinSensorIcon,
  ToesForceToMaxSensorIcon,
  ToesForceToMinSensorIcon,
  TorqueForceSensorIcon,
  TorqueForceToMaxSensorIcon,
  TorqueForceToMinSensorIcon,
} from 'components/icons';
import { MainHeading } from 'components/texts/MainHeading';
import { TranslateText } from 'components/texts/TranslateText';

import { CalibrationFlowBackButton } from '../CalibrationFlowBackButton';
import { CalibrationFlowNextButton } from '../CalibrationFlowNextButton';
import { BasingSignalBar } from '../components/BasingSignalBar';

export interface ForceCalibrationData {
  mvc: { [key in string]?: number };
  threshold: { [key in string]?: number };
}

export function ForceSensorIcon({
  forceSensor,
  direction,
  boxSize,
}: {
  forceSensor: string;
  direction?: 'toMin' | 'toMax' | '';
  boxSize: BoxProps['boxSize'];
}) {
  switch (forceSensor) {
    case 'knee':
      return direction === 'toMin' ? (
        <KneeForceToMinSensorIcon boxSize={boxSize} />
      ) : (
        <KneeForceToMaxSensorIcon boxSize={boxSize} />
      );
    case 'heel':
      return direction === 'toMin' ? (
        <HeelForceToMinSensorIcon boxSize={boxSize} />
      ) : (
        <HeelForceToMaxSensorIcon boxSize={boxSize} />
      );
    case 'toes':
      return direction === 'toMin' ? (
        <ToesForceToMinSensorIcon boxSize={boxSize} />
      ) : (
        <ToesForceToMaxSensorIcon boxSize={boxSize} />
      );
    case 'extension':
      return <ExtensionForceSensorIcon boxSize={boxSize} />;
    case 'torque':
      return direction === 'toMin' ? (
        <TorqueForceToMinSensorIcon boxSize={boxSize} />
      ) : direction === 'toMax' ? (
        <TorqueForceToMaxSensorIcon boxSize={boxSize} />
      ) : (
        <TorqueForceSensorIcon boxSize={boxSize} />
      );
  }

  return <></>;
}

const exerciseDescriptions = {
  'cam-turn-key': function ExerciseDescriptionTurnKey() {
    return (
      <VStack data-testid="body-field" alignItems="flex-start">
        <TranslateText variant="openSans24" textAlign="justify">
          <TranslateText text={'exercise.calibration.force.forceDescription1'} />
          <TranslateText mt={10}>
            <TranslateText as="span" variant="openSans24Bold" text={'exercise.calibration.force.forceDescription2'} />
            <TranslateText as="span" text={'exercise.calibration.force.forceDescription3'} />
            <TranslateText as="span" variant="openSans24Bold" text={'exercise.calibration.force.forceDescription4'} />
          </TranslateText>
          <TranslateText>
            <TranslateText as="span" variant="openSans24Bold" text={'exercise.calibration.force.forceDescription5'} />
            <TranslateText as="span" text={'exercise.calibration.force.forceDescription6'} />
            <TranslateText as="span" variant="openSans24Bold" text={'exercise.calibration.force.forceDescription7'} />
          </TranslateText>
          <TranslateText mt={10} text={'exercise.calibration.force.forceDescription8'} />
        </TranslateText>
      </VStack>
    );
  },
  default: function ExerciseDescriptionDefault() {
    return (
      <VStack data-testid="body-field" alignItems="flex-start">
        <TranslateText variant="openSans24" textAlign="justify">
          <TranslateText text={'exercise.calibration.force.forceDescriptionDefault'} />
          <TranslateText mt={8} text={'exercise.calibration.force.forceDescriptionDefault2'} />
        </TranslateText>
      </VStack>
    );
  },
};

function BasingSignalBars({
  flow,
  sensorForcePlugin,
  handleDataChange,
  forceSensors,
}: {
  flow: CalibrationFlow;
  sensorForcePlugin: SensorForcePlugin;
  handleDataChange: (forceSensor: string, mvc: number, threshold: number, handleDataChange: boolean) => void;
  forceSensors: { name: SensorsName; trigger: boolean }[];
}) {
  const exerciseParameters = flow.getStateData('basing-settings')?.exerciseParameters as {
    [key in SettingsParameterId]: unknown;
  };
  const isReversed = exerciseParameters['cpm.0.movementType'] === 'reverse';
  const direction =
    (exerciseParameters['cam.0.activeMovementDirection'] as CAMActiveMovementDirection | undefined) ?? null;
  const theme = useTheme();

  useEffect(() => {
    sensorForcePlugin.startSensorForceMeasurement();

    return () => {
      delete sensorForcePlugin.onSensorData;
      sensorForcePlugin.stopSensorForceMeasurement();
    };
  }, [isReversed, sensorForcePlugin]);

  const values = useMemo(
    () =>
      forceSensors.reduce(
        (prev, forceSensor) => (
          (prev[forceSensor.name] = computed(() => {
            const value = sensorForcePlugin.signals[forceSensor.name].value;
            return isReversed
              ? // for reverse movement allow only values below 0
                value <= 0
                ? value
                : // unless we deal with unidirectional extenstion
                sensorForcePlugin.isUnidirectional(forceSensor.name)
                ? // then allow values above 0
                  value
                : 0
              : // for normal movement do nothing
                value;
          }, `BasingSignalBars.${forceSensor.name}.values`)),
          prev
        ),
        {} as Record<SensorsName, ReadonlySignal<number>>,
      ),
    [forceSensors, isReversed, sensorForcePlugin],
  );

  const onChange = useCallback(
    (forceSensor: string, mvc: number, threshold: number) => {
      handleDataChange(
        forceSensor,
        isReversed && !sensorForcePlugin.isUnidirectional(forceSensor as SensorsName)
          ? mvc >= 0
            ? -mvc
            : Math.abs(mvc)
          : mvc,
        threshold,
        forceSensor.includes('Negative') || isReversed,
      );
    },
    [handleDataChange, isReversed, sensorForcePlugin],
  );

  return (
    <>
      {forceSensors.map(forceSensor => {
        if (!forceSensor.trigger && !sensorForcePlugin.isUnidirectional(forceSensor.name)) {
          return (
            <React.Fragment key={forceSensor.name}>
              <BasingSignalBar
                isNegative={true}
                lightColor={theme.colors.noteBackgroundColor}
                color={theme.colors.egzotechPrimaryColor}
                value={values[forceSensor.name]}
                active={true}
                onChange={onChange}
                isThresholdDisabled={direction === 'toMax'}
                unit={forceSensor.name === 'torque' ? 'units.newtonMeters' : 'units.kg'}
                maxValueTitle={'exercise.calibration.force.maxValue'}
                resetTitle="exercise.calibration.force.resetMaxValue"
                name={`${forceSensor.name}Negative`}
                topLabel={
                  <ForceSensorIcon
                    forceSensor={forceSensor.name}
                    direction={'toMin'}
                    boxSize={{ base: 20, '2xl': 28 }}
                  />
                }
              />
              <BasingSignalBar
                isNegative={false}
                lightColor={theme.colors.noteBackgroundColor}
                color={theme.colors.egzotechPrimaryColor}
                value={values[forceSensor.name]}
                active={true}
                onChange={onChange}
                isThresholdDisabled={direction === 'toMin'}
                unit={forceSensor.name === 'torque' ? 'units.newtonMeters' : 'units.kg'}
                maxValueTitle={'exercise.calibration.force.maxValue'}
                resetTitle="exercise.calibration.force.resetMaxValue"
                name={forceSensor.name}
                topLabel={
                  <ForceSensorIcon
                    forceSensor={forceSensor.name}
                    direction={'toMax'}
                    boxSize={{ base: 20, '2xl': 28 }}
                  />
                }
              />
            </React.Fragment>
          );
        }

        return (
          <BasingSignalBar
            isNegative={isReversed && !sensorForcePlugin.isUnidirectional(forceSensor.name)}
            lightColor={theme.colors.noteBackgroundColor}
            color={theme.colors.egzotechPrimaryColor}
            key={forceSensor.name}
            value={values[forceSensor.name]}
            active={true}
            onChange={onChange}
            isThresholdDisabled={false}
            unit={forceSensor.name === 'torque' ? 'units.newtonMeters' : 'units.kg'}
            maxValueTitle={'exercise.calibration.force.maxValue'}
            resetTitle="exercise.calibration.force.resetMaxValue"
            name={forceSensor.name}
            topLabel={
              <ForceSensorIcon
                forceSensor={forceSensor.name}
                direction={isReversed ? 'toMin' : 'toMax'}
                boxSize={{ base: 20, '2xl': 28 }}
              />
            }
          />
        );
      })}
    </>
  );
}

export function findForceSources(definition: GeneratedExerciseDefinition) {
  const result: { name: SensorsName; trigger: boolean }[] = [];

  if (isSpecificGeneratedExerciseDefinition(definition, ['cpm', 'cpm-emg', 'cpm-ems', 'cpm-ems-emg', 'cpm-force'])) {
    for (const prop in definition.cpm) {
      result.push(
        ...(definition.cpm[prop as MotorPlacement]?.program?.phases
          .map(v => v.forceTriggerSource)
          .filter(v => v)
          .map(v => ({
            name: v as SensorsName,
            trigger: true,
          })) ?? []),
      );
    }
  }

  if (isCAMExerciseDefinition(definition)) {
    for (const prop in definition.cam) {
      result.push(
        ...(definition.cam[prop as MotorPlacement]?.program?.phases
          .map(v => v.forceSource)
          .filter(v => v)
          .map(v => ({
            name: v as SensorsName,
            trigger: false,
          })) ?? []),
      );

      result.push(
        ...(definition.cam[prop as MotorPlacement]?.program?.phases
          .map(v => v.forceTriggerSource)
          .filter(v => v)
          .map(v => ({
            name: v as SensorsName,
            trigger: true,
          })) ?? []),
      );
    }
  }

  return result;
}

export const ForceCalibration = memo(({ flow }: { flow: CalibrationFlow }) => {
  const { session } = useDevice();
  const { selectedProgram } = useProgramSelection();
  const definition =
    (flow.getStateData('basing-settings')?.definition as GeneratedExerciseDefinition) ?? selectedProgram;

  const forceSensors = useMemo(() => {
    if (!definition) {
      throw Error('Program should be available here');
    }

    return findForceSources(definition);
  }, [definition]);

  const [forceCalibrationData, setForceCalibrationData] = useCalibrationFlowState(flow, 'force-calibration');

  const sensorForcePlugin = useMemo(() => {
    if (!session) {
      throw new Error('Cannot use calibration without session');
    }

    return new SensorForcePlugin(
      session,
      forceSensors.map(v => v.name),
    );
  }, [forceSensors, session]);

  const handleDataChange = useCallback(
    (forceSensor: string, mvc: number, threshold: number, negativeSensor: boolean) => {
      const sensorNameWithoutNegative = forceSensor.replace('Negative', '');
      const previousThreshold = negativeSensor
        ? sensorForcePlugin!.signalsAdditionalData[sensorNameWithoutNegative as SensorsName]!.negativeThreshold!
            .value ?? 50
        : sensorForcePlugin!.signalsAdditionalData[sensorNameWithoutNegative as SensorsName]!.positiveThreshold!
            .value ?? 50;

      const type = threshold > previousThreshold ? 'increase-force-threshold' : 'decrease-force-threshold';

      const initLoad = previousThreshold === 0 && threshold === 50;

      if (threshold !== previousThreshold && !initLoad) {
        exerciseActionTracker.add('force-threshold-change', type, {
          sensorName: sensorNameWithoutNegative as SensorsName,
          from: Math.round(previousThreshold),
          to: Math.round(threshold),
          isNegative: negativeSensor ?? false,
          time: Date.now(),
          description: `trainingReport.exerciseTimeline.events.${type}`,
        });
      }
      if (negativeSensor && !sensorForcePlugin?.isUnidirectional(sensorNameWithoutNegative as SensorsName)) {
        sensorForcePlugin!.signalsAdditionalData[sensorNameWithoutNegative as SensorsName]!.negativeThreshold!.value =
          threshold;
      }

      if (!negativeSensor) {
        sensorForcePlugin!.signalsAdditionalData[forceSensor as SensorsName]!.positiveThreshold!.value = threshold;
      }

      if (flow.finished) {
        return;
      }

      setForceCalibrationData(data => {
        // pass threshold only for triggered channels
        return {
          mvc: { ...(data?.mvc ?? 0), [forceSensor]: mvc },
          threshold: { ...(data?.threshold ?? 0), [forceSensor]: threshold / 100 },
        };
      });
    },
    [flow.finished, sensorForcePlugin, setForceCalibrationData],
  );

  const Description =
    exerciseDescriptions[selectedProgram?.type as keyof typeof exerciseDescriptions] ?? exerciseDescriptions.default;

  const validationFn = (v: { name: SensorsName; trigger: boolean }) => {
    return (
      forceCalibrationData?.mvc &&
      typeof forceCalibrationData.mvc[v.name as keyof typeof forceCalibrationData.mvc] === 'number' &&
      Object.values(forceCalibrationData.mvc).every(
        value => Math.abs(value) > (sensorForcePlugin.getNoiseThreshold(v.name) ?? 0),
      )
    );
  };

  return (
    <Container variant="calibrationFlowMainWrapper" data-testid="emg-calibration-container">
      <Flex w={'100%'} px={4} py={12} ml={24} my={'auto'} justifyContent={'space-around'}>
        <BasingSignalBars
          flow={flow}
          sensorForcePlugin={sensorForcePlugin}
          handleDataChange={handleDataChange}
          forceSensors={forceSensors}
        />
      </Flex>
      <Container variant="calibrationFlowDescriptionWrapper" ml={{ lg: 5, '2xl': 16 }}>
        <MainHeading variant="subheading36" text="exercise.calibration.heading" data-testid="header-field" />
        <Description />
        <Flex justifyContent="space-between" width="100%" alignSelf="center" mt="6">
          <CalibrationFlowBackButton flow={flow} />
          <CalibrationFlowNextButton
            flow={flow}
            isFormValidation
            validationPassed={forceSensors.every(v => validationFn(v))}
          />
        </Flex>
      </Container>
    </Container>
  );
});

ForceCalibration.displayName = 'ForceCalibration';
