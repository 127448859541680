import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Container } from '@chakra-ui/react';
import { ChannelConnectionQuality } from '@egzotech/exo-session/features/cable';
import { Channel } from 'config/theme/colors';
import { useAppSelector } from 'hooks/store';
import { CalibrationFlow } from 'libs/exo-session-manager/core';
import { channelColors, useCalibrationFlowState, useDevice } from 'libs/exo-session-manager/react';
import { CPMElectrostimInstanceContext } from 'libs/exo-session-manager/react/providers/CPMElectrostimInstanceContext';
import { BodyPartMuscle, ConnectedChannelToMuscle, ElectrodeStatus, ProgressStep } from 'types';
import {
  ChannelRole,
  ChannelToRoleMap,
  InstanceToRolesMap,
} from 'views/+patientId/training/+trainingId/_components/ChannelRoleSelector';

import { MuscleToChannelMap } from './ChannelMuscleSelector';
import { ConnectElectrodesStepsProgress } from './ConnectElectrodesStepsProgress';
import { ConnectElectrodesStepStatus } from './ConnectElectrodesStepStatus';

export type ChannelRoleInstances = {
  [x: number]: ChannelToRoleMap;
};

export const getChannelRoles = (
  instances?: InstanceToRolesMap,
  instanceId: number | null = 0,
  combineInstances = true,
) => {
  const combinedRoles: Partial<ChannelToRoleMap> = {};
  let currentInstanceId = 0;
  do {
    let channelRoles = instances?.[currentInstanceId] as ChannelToRoleMap;
    if (!combineInstances && currentInstanceId !== instanceId && instanceId !== null) {
      currentInstanceId++;
      continue;
    }
    if (!channelRoles) {
      channelRoles = {} as ChannelToRoleMap;
    }

    for (const c of Object.keys(channelRoles).map(v => Number(v)) as Channel[]) {
      const combinedChannelRoles = channelRoles[c]
        .map(feature => {
          if (instanceId === null) {
            return feature;
          }
          return feature === 'emg'
            ? feature
            : instanceId === currentInstanceId
            ? feature
            : feature === 'trigger'
            ? 'inactive-trigger'
            : '';
        })
        .filter(i => i !== '') as ChannelRole[];
      combinedRoles[c] = Array.from(new Set([...(combinedRoles[c] ?? []), ...combinedChannelRoles]));
    }

    currentInstanceId++;
  } while (currentInstanceId < Object.keys(instances ?? {}).length);

  return combinedRoles as ChannelToRoleMap;
};

export const useChannelRoleSelector = (flow: CalibrationFlow, instanceId: number | null = 0) => {
  const cpmEmsInstance = useContext(CPMElectrostimInstanceContext);
  const [data, setData] = useCalibrationFlowState(flow, 'channel-role-selector');

  const combinedRoles = useMemo(() => {
    if (cpmEmsInstance) {
      cpmEmsInstance?.selectEMSInstance(instanceId === null ? 0 : instanceId);
    }
    return { channelRoles: getChannelRoles(data?.channelRolesInstances, instanceId, false) };
  }, [data?.channelRolesInstances, instanceId, cpmEmsInstance]);

  const combinedSetter = useCallback(
    (channelMap: ChannelToRoleMap) => {
      setData(prev => {
        if (instanceId === null) {
          throw new Error(`Cannot set roles without instanceId`);
        }
        const dataToSet = {
          channelRolesInstances: { ...prev?.['channelRolesInstances'], ...{ [instanceId]: channelMap } },
        };
        return dataToSet;
      });
    },
    [instanceId, setData],
  );

  return [combinedRoles, combinedSetter] as const;
};

export const ConnectElectrodes = ({ flow }: { flow: CalibrationFlow }) => {
  const { selectedDevice } = useDevice();
  const { currentTemplate, currentExercise } = useAppSelector(state => state.training);
  const [, setCalibrationData] = useCalibrationFlowState(flow, 'connect-electrodes');
  const [channelMuscleSelectorData] = useCalibrationFlowState(flow, 'channel-muscle-selector');
  const [channelRoleSelectorData] = useChannelRoleSelector(flow);
  const [sidraData] = useCalibrationFlowState(flow, 'leg-basing-leg-side-selection');
  const [meissaData] = useCalibrationFlowState(flow, 'meissa-basing-side-selection');

  const muscleToChannelMap = channelMuscleSelectorData?.muscleToChannel as MuscleToChannelMap | null;
  const channelRoles = channelRoleSelectorData?.channelRoles;

  if (!muscleToChannelMap) {
    throw new Error('Missing data from channel-muscle-selector flow state');
  }

  const [bodyParts] = useState(
    Object.entries(muscleToChannelMap ?? {})
      .map(([k, v]) => ({
        channelFeature: [
          ...(channelRoles?.[v].includes('emg') || channelRoles?.[v].includes('trigger') ? ['emg'] : []),
          ...(channelRoles?.[v].includes('electrostim') ? ['ems'] : []),
        ],
        muscleId: k,
        regionId: 'leg',
        type: 'muscle',
        channelNumber: v,
      }))
      .sort((a, b) => a.channelNumber - b.channelNumber) as (BodyPartMuscle & { channelNumber?: number })[],
  );

  const [currentElectrodeIndex, setElectrodeIndex] = useState(0);

  const [electrodeStatus, setElectrodeStatus] = useState<ElectrodeStatus[]>(
    Array.from({ length: bodyParts.length }, (_, idx) => {
      return { touched: !idx, timedOut: false };
    }),
  );

  const connectedChannels = useMemo(() => {
    if (!selectedDevice?.channelsConnectionQuality) return null;
    return Object.entries(selectedDevice?.channelsConnectionQuality);
  }, [selectedDevice]);

  const mappedSteps: ProgressStep[] = useMemo(() => {
    if (!bodyParts) {
      throw new Error('No body parts defined - cannot perform electrode connection');
    }
    return bodyParts.map((bodyPart, idx: number) => {
      return {
        label: ((bodyPart.channelNumber ?? idx) + 1).toString(),
        color: channelColors[idx],
        currentStep: idx == currentElectrodeIndex,
        channelIndex: bodyPart.channelNumber ?? idx,
        status: connectedChannels ? connectedChannels[bodyPart.channelNumber ?? idx][1] : ChannelConnectionQuality.NONE,
        touched: electrodeStatus[idx].touched,
        timedOut: electrodeStatus[idx].timedOut,
        muscle: bodyPart,
      };
    });
  }, [bodyParts, electrodeStatus, connectedChannels, currentElectrodeIndex]);

  useEffect(() => {
    const connectedChannelsToMuscles = mappedSteps.map<ConnectedChannelToMuscle>(({ channelIndex, muscle }) => ({
      channelIndex,
      muscle,
    }));
    setCalibrationData({ connectedChannelsToMuscles });
  }, [bodyParts, mappedSteps, setCalibrationData]);

  const onBackClickHandler = () => {
    setElectrodeStatus(prev =>
      prev.map((status, i) =>
        i === currentElectrodeIndex
          ? {
              timedOut: false,
              touched: true,
            }
          : status,
      ),
    );

    if (currentElectrodeIndex !== 0) {
      setElectrodeIndex(currentElectrodeIndex - 1);
    }
  };

  const onNextClickHandler = () => {
    setElectrodeStatus(prev =>
      prev.map((status, i) =>
        i === currentElectrodeIndex + 1
          ? {
              timedOut: false,
              touched: true,
            }
          : status,
      ),
    );

    if (currentElectrodeIndex < mappedSteps.length - 1) {
      setElectrodeIndex(currentElectrodeIndex + 1);
    }
  };

  const side = (sidraData?.sideSelection ?? meissaData?.sideSelection) as 'right' | 'left' | undefined;

  return (
    <>
      {connectedChannels && currentTemplate && (
        <Container variant="calibrationFlowMainWrapper">
          <ConnectElectrodesStepsProgress
            variant="calibrationFlowIlustrationWrapper"
            steps={mappedSteps}
            onSelectStep={idx => setElectrodeIndex(idx)}
            currentStep={currentElectrodeIndex}
            tempProgressHide={false}
            showChannelBadge={true}
            side={side}
          />
          <ConnectElectrodesStepStatus
            step={mappedSteps[currentElectrodeIndex]}
            currentMuscle={bodyParts[currentElectrodeIndex]}
            // TODO: should be removed?
            onActivatePort={() => {}}
            electrodesConnectionState={{
              allDisconnected: currentElectrodeIndex === 0,
              allConnected: currentElectrodeIndex === mappedSteps.length - 1,
            }}
            flow={flow}
            currentExercise={currentTemplate?.exercises[currentExercise]}
            currentElectrode={currentElectrodeIndex}
            onBackClick={onBackClickHandler}
            onNextClick={onNextClickHandler}
          />
        </Container>
      )}
    </>
  );
};
