import { MultiChannelSignalRecorder, Recordable, SignalRecorder } from '@egzotech/exo-session/features/common';

export type DefaultSignalRecorderId =
  | `${'knee' | 'toes' | 'heel' | 'torque' | 'extension'}-force`
  | `${'knee' | 'ankle' | 'main'}-angle`;

export type Recordings<T extends string = DefaultSignalRecorderId> = {
  [key in T]?: {
    samples: Float32Array;
    timePoints: Uint32Array;
  };
} & {
  emg?: Record<
    number,
    {
      samples: Float32Array;
      timePoints: Uint32Array;
    }
  >;
};

export type RecorderController<T extends string = DefaultSignalRecorderId> =
  | {
      id: T;
      recorder: SignalRecorder;
    }
  | {
      id: 'emg';
      recorder: MultiChannelSignalRecorder;
    };

export class SignalRecorderController {
  private recorders: RecorderController[] = [];
  private intervalId: NodeJS.Timer | null = null;
  private timePoint = 0;

  private _startTime = 0;
  private _pauseTime: number | null = null;

  static INTERVAL_TIME = 50;

  get started() {
    return this._startTime !== 0;
  }

  constructor(private recordables: Recordable<'single' | 'multi'>[], private channels?: number[]) {
    recordables.forEach(recordable => {
      if (recordable.recordableType === 'multi') {
        if (recordable.recordableId === 'emg' && channels) {
          this.recorders.push({ id: recordable.recordableId, recorder: new MultiChannelSignalRecorder(channels) });
        }
      } else {
        this.recorders.push({ id: recordable.recordableId as DefaultSignalRecorderId, recorder: new SignalRecorder() });
      }
    });
  }

  start() {
    this.recorders.forEach(v => v.recorder.start());
    this._startTime = Date.now();
    this.record();
  }

  pause() {
    this.recorders.forEach(v => v.recorder.pause());
    if (this._startTime > 0) {
      this._pauseTime = Date.now();
    }
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  resume() {
    this.recorders.forEach(v => v.recorder.resume());
    this.record();
  }

  private record() {
    this.intervalId = setInterval(() => {
      this.recordables.forEach(recordable => {
        if (recordable.recordableType === 'multi' && recordable.recordableId === 'emg') {
          const emgRecorder = this.recorders.find(({ id }) => id === recordable.recordableId)?.recorder as
            | MultiChannelSignalRecorder
            | undefined;
          const snapshot = (recordable as Recordable<'multi'>).getSnapshot();
          emgRecorder?.record(snapshot, Date.now() - this._startTime);
        } else {
          const recorder = this.recorders.find(({ id }) => id === recordable.recordableId)?.recorder as SignalRecorder;
          const snapshot = (recordable as Recordable<'single'>).getSnapshot();
          const lastSnapshotValue = snapshot?.at(-1);
          if (recorder && typeof lastSnapshotValue === 'number') {
            recorder.record(lastSnapshotValue, Date.now() - this._startTime);
          }
        }
      });
      this.timePoint += SignalRecorderController.INTERVAL_TIME;
    }, SignalRecorderController.INTERVAL_TIME);
  }

  stop() {
    this.pause();
    this.timePoint = 0;
    this._startTime = 0;
    this._pauseTime = null;
    const recordings: Recordings = {};
    this.recorders.forEach(v => {
      if (v.id === 'emg') {
        const emgRecorder = v.recorder as MultiChannelSignalRecorder;
        const emg = emgRecorder.stop().reduce(
          (prev, curr, i) => {
            if (curr && this.channels) {
              prev[this.channels[i]] = curr;
              return prev;
            }
            return prev;
          },
          {} as Record<
            number,
            {
              samples: Float32Array;
              timePoints: Uint32Array;
            }
          >,
        );
        recordings.emg = emg;
      } else {
        const recorder = v.recorder as SignalRecorder;
        const record = recorder.stop();
        if (record) {
          recordings[v.id] = record;
        }
      }
    });
    return recordings;
  }
}
