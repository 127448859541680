import { DeepReadonly, ExoSession } from '@egzotech/exo-session';
import { ChannelConnectionQuality } from '@egzotech/exo-session/features/cable';
import { ExoEMGFeature } from '@egzotech/exo-session/features/emg';
import { Logger } from '@egzotech/universal-logger-js';
import { Signal, signal } from 'helpers/signal';
import { getChannelRoles } from 'views/+patientId/training/+trainingId/_components/ConnectElectrodes';

import { CalibrationFlowStatesTypedData, exerciseActionTracker } from '..';

import { calculatePortStateVoltage } from './calculations';
import { Triggerable } from './Triggerable';

export interface EMGPortState {
  active: boolean;
  channelNumber: number;
  /**
   * Voltage (uV)
   */
  currentVoltage: number;
  /**
   * Voltage (uV)
   */
  maxVoltage: number;
  /**
   * Voltage (uV)
   */
  calculatedThresholdValue?: number;
  slider: number;
}

export type ChannelSignal = {
  currentValue: number;
  maxValue: number;
  threshold: number;
};

export type ChannelNumber = number;

export interface EMGSignalData {
  enabled: Signal<boolean>;
  initialized: boolean;
  calibrated: Signal<boolean>;
  portsStates: Signal<EMGPortState>[];
  channelsSignals: Record<ChannelNumber, Signal<ChannelSignal>>;

  /**
   * Percentage of MVC (%)
   */
  programThresholdFactor: number;
}

export interface EMGCalibration {
  setPortSlider: (port: number, slider: number) => void;
  resetMVC: (port: number) => void;
  setCalibrated: (calibrated: boolean) => void;
}

export interface EMGSignalContext extends EMGCalibration, EMGSignalData {
  activatePort: (port: number, activated: boolean) => void;
  initialize: (channels: Record<number, ChannelConnectionQuality>) => void;
  enable: (channels: Record<number, ChannelConnectionQuality>) => void;
  disable: () => void;
}

export default class EMGSignal implements EMGCalibration {
  static createFromCalibrationFlow(
    session: ExoSession,
    calibrationData: DeepReadonly<CalibrationFlowStatesTypedData>,
    triggerables: Triggerable[],
  ) {
    const emgSignal = new EMGSignal(session, undefined, triggerables);
    if (!calibrationData) {
      throw new Error('This exercise was never prepared');
    }

    const channelRoles = getChannelRoles(calibrationData['channel-role-selector']?.channelRolesInstances);

    const connectedEMGChannels = Object.entries(channelRoles)
      .filter(([_, v]) => v.some(i => i === 'emg' || i === 'trigger' || i === 'inactive-trigger'))
      .map(([k, _]) => Number(k));

    emgSignal.initialize(
      connectedEMGChannels.reduce(
        (prev, next) => ((prev[next] = ChannelConnectionQuality.WELL), prev),
        {} as Record<number, ChannelConnectionQuality>,
      ),
    );

    emgSignal.enable(emgSignal.channels);

    emgSignal.data.portsStates.forEach((_, i) => emgSignal!.activatePort(i, true));

    const mvc = calibrationData['emg-calibration']?.mvc as { [key: number]: number } | undefined;
    const threshold = calibrationData['emg-calibration']?.threshold as { [key: number]: number } | undefined;

    // for exercise with EMG trigger
    if (mvc && threshold) {
      for (let i = 0; i < connectedEMGChannels.length; i++) {
        const channel = connectedEMGChannels[i];
        const port = emgSignal.data.portsStates.findIndex(v => v.peek().channelNumber === channel);

        if (typeof threshold[channel] === 'number') {
          emgSignal.setPortSlider(port, threshold[channel], false);
        }

        emgSignal.setPortMVC(port, mvc[channel]);
      }
    }

    emgSignal.disable();
    emgSignal.setCalibrated(true);
    return emgSignal;
  }

  private _signalData: EMGSignalData;
  private _emgFeature: ExoEMGFeature | null = null;
  private _channels: Record<number, ChannelConnectionQuality> = {};
  private triggerChannels: number[] | null = null;
  private _displayDataInterval?: ReturnType<typeof setInterval>;

  static readonly DEFAULT_PROGRAM_THRESHOLD_FACTOR = 0.5;
  static readonly EMG_DEFAULT_SLIDER_VALUE = 0.5;

  static readonly logger = Logger.getInstance('EMGSignal');

  get calibrated() {
    return this._signalData.calibrated.peek();
  }

  constructor(
    private readonly session: ExoSession,
    programThresholdFactor?: number,
    private readonly emgTriggers?: Triggerable[],
  ) {
    this._signalData = {
      enabled: signal(false, 'EMGSignal._signalData.enabled'),
      initialized: false,
      calibrated: signal(false, 'EMGSignal._signalData.calibrated'),
      portsStates: [],
      programThresholdFactor: programThresholdFactor
        ? programThresholdFactor
        : EMGSignal.DEFAULT_PROGRAM_THRESHOLD_FACTOR,
      channelsSignals: {},
    };
  }

  get data() {
    return this._signalData;
  }

  get channels() {
    return this._channels;
  }

  get channelMask() {
    return this._signalData.portsStates
      .filter(portState => portState.peek().active)
      .map(portState => portState.peek().channelNumber);
  }

  setCalibrated(calibrated: boolean) {
    this._signalData.calibrated.value = calibrated;
  }

  setTriggerChannels(channels: number[] | null) {
    this.triggerChannels = channels;
  }

  initialize(channels: Record<number, ChannelConnectionQuality>) {
    if (!this._emgFeature) {
      this._emgFeature = this.session.activate(ExoEMGFeature);
    }

    this._signalData.portsStates = Object.entries(channels).map<Signal<EMGPortState>>(([channel]) =>
      signal(
        calculatePortStateVoltage(
          {
            active: false,
            channelNumber: +channel,
            currentVoltage: 0,
            maxVoltage: 0,
            slider: EMGSignal.EMG_DEFAULT_SLIDER_VALUE,
          },
          this._signalData.programThresholdFactor,
        ),
        'EMGSignal._signalData.portStates.' + channel,
      ),
    );

    this._signalData.channelsSignals = Object.keys(channels).reduce(
      (prev, next) => (
        (prev[+next] = signal(
          { currentValue: 0, maxValue: 0, threshold: EMGSignal.EMG_DEFAULT_SLIDER_VALUE },
          'EMGSignal._signalData.channelsSignals.' + next,
        )),
        prev
      ),
      {} as Record<
        number,
        Signal<{
          currentValue: number;
          maxValue: number;
          threshold: number;
        }>
      >,
    );

    this._channels = channels;
    this._signalData.initialized = true;
  }

  enable(channels: Record<number, ChannelConnectionQuality>) {
    if (this._signalData.enabled.peek()) {
      EMGSignal.logger.info('enable', 'EMG Signal monitoring already enabled');
      return;
    }
    if (!this._emgFeature) {
      EMGSignal.logger.error('enable', 'Cannot start EMG signal monitoring, when feature is not active');
      return;
    }
    if (!this._emgFeature.canEnable(Object.keys(channels).map(channel => +channel))) {
      EMGSignal.logger.error('enable', 'Cannot enable EMG on selected channels with given cable');
      return;
    }

    this._signalData.portsStates.forEach(
      portState =>
        (portState.value = {
          ...calculatePortStateVoltage(
            portState.peek(),
            this._signalData.programThresholdFactor,
            this._signalData.calibrated.peek(),
          ),
        }),
    );

    this._emgFeature.enable(this.channelMask);

    this._emgFeature.onEmg = (channels, _timeDelta) => {
      for (const channel in channels) {
        const portState = this._signalData.portsStates.find(v => v.peek().channelNumber === +channel);

        if (!portState) {
          continue;
        }

        portState.peek().currentVoltage = channels[channel];
      }

      for (const idx in this._signalData.portsStates) {
        calculatePortStateVoltage(
          this._signalData.portsStates[idx].peek(),
          this._signalData.programThresholdFactor,
          this._signalData.calibrated.peek(),
        );
      }

      this.emgTriggers?.forEach(trigger => trigger.trigger());
    };

    this._displayDataInterval = setInterval(() => this.updateDisplayData(), 100);

    this._channels = channels;
    this._signalData.enabled.value = true;
  }

  disable() {
    if (!this._signalData.enabled.peek()) {
      EMGSignal.logger.info('disable', 'Cannot disable EMG monitoring that is not yet enabled');
      return;
    }
    if (!this._emgFeature) {
      EMGSignal.logger.error('disable', 'Cannot disable EMG signal monitoring, when feature is not active');
      return;
    }

    clearInterval(this._displayDataInterval);

    this._emgFeature.disable();
    this._emgFeature.onEmg = undefined;
    this._signalData.enabled.value = false;
  }

  activatePort(port: number, activated: boolean) {
    if (!this._signalData.initialized) {
      EMGSignal.logger.info('activatePort', 'Cannot activate port on program that is ended or not yet started');
      return;
    }
    if (!(port >= 0 && port <= this._signalData.portsStates.length)) {
      EMGSignal.logger.error('activatePort', `Port ${port} is invalid in this program`);
      return;
    }

    this._signalData.portsStates[port].value = {
      ...this._signalData.portsStates[port].peek(),
      active: activated,
    };
  }

  setPortSlider(port: number, slider: number, record = true) {
    if (!this._signalData.enabled.peek()) {
      EMGSignal.logger.info('setPortSlider', 'Cannot set port slider on program that is ended or not yet started');
      return;
    }
    if (!(port >= 0 && port <= this._signalData.portsStates.length)) {
      EMGSignal.logger.error('setPortSlider', `Port ${port} is invalid in this program`);
      return;
    }
    const oldValue = this._signalData.portsStates[port].peek().slider;

    this._signalData.portsStates[port].value = {
      ...this._signalData.portsStates[port].value,
      slider,
    };

    this._signalData.portsStates[port].value = {
      ...calculatePortStateVoltage(
        this._signalData.portsStates[port].peek(),
        this._signalData.programThresholdFactor,
        this._signalData.calibrated.peek(),
      ),
    };

    if (record && oldValue !== slider) {
      exerciseActionTracker.add('parameter-adjustment', `emg-threshold-change`, {
        type: 'emg-parameter-change',
        description: 'trainingReport.exerciseTimeline.events.parameter-change',
        paramDescription: `training.settings.parameters.threshold`,
        repetition: 0,
        from: Math.round(oldValue * 100),
        to: Math.round(slider * 100),
        time: Date.now(),
        unit: '%',
        channel: this._signalData.portsStates[port].peek().channelNumber,
      });
    }

    this.updateDisplayData();
  }

  setPortMVC(port: number, mvc: number) {
    const old = this._signalData.portsStates[port].peek().currentVoltage;
    const newValue = { ...this._signalData.portsStates[port].value };
    newValue.maxVoltage = mvc;

    calculatePortStateVoltage(newValue, this._signalData.programThresholdFactor, this._signalData.calibrated.peek());

    newValue.currentVoltage = old;
    this._signalData.portsStates[port].value = newValue;
    this.updateDisplayData();
  }

  resetMVC(port: number) {
    if (!this._signalData.enabled.peek()) {
      EMGSignal.logger.info('resetMVC', 'Cannot reset MVC on program that is ended or not yet started');
      return;
    }

    this._signalData.portsStates[port].value = {
      ...calculatePortStateVoltage(
        {
          ...this._signalData.portsStates[port].peek(),
          maxVoltage: 0,
          slider: EMGSignal.EMG_DEFAULT_SLIDER_VALUE,
        },
        this._signalData.programThresholdFactor,
      ),
    };

    this.updateDisplayData();
  }

  isThresholdReached(channel?: number) {
    if (channel !== undefined) {
      const portState = this._signalData.portsStates.find(v => v.peek().channelNumber === channel);

      if (!portState) {
        return false;
      }

      return portState.peek().currentVoltage >= (portState.peek().calculatedThresholdValue ?? 0);
    }

    return this._signalData.portsStates
      .filter(
        portState =>
          portState.peek().active &&
          (!this.triggerChannels || this.triggerChannels.includes(portState.peek().channelNumber)),
      )
      .every(portState => portState.peek().currentVoltage >= (portState.peek().calculatedThresholdValue ?? 0));
  }

  getRecordables() {
    if (!this._emgFeature) {
      throw new Error('Cannot get recordables before initialization');
    }

    return [this._emgFeature];
  }

  dispose() {
    this.disable();
    this._emgFeature?.dispose();
    this._emgFeature = null;
  }

  private updateDisplayData() {
    for (const idx in this._signalData.portsStates) {
      this.data.channelsSignals[this._signalData.portsStates[idx].peek().channelNumber].value = {
        currentValue: this._signalData.portsStates[idx].peek().currentVoltage,
        maxValue: this._signalData.portsStates[idx].peek().maxVoltage,
        threshold: this._signalData.portsStates[idx].peek().slider,
      };
    }
  }
}
