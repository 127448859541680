import { GeneratedCPMExerciseDefinition } from '../../types/GeneratedExerciseDefinition';

export const getPredefinedMeissaOtCPMEMGExercises = (): { [key: string]: GeneratedCPMExerciseDefinition } => ({
  cpm_emg_classic_meissa: {
    type: 'cpm-emg',
    calibrationFlow: 'cpm-emg/meissa',
    tags: ['emg'],
    cpm: {
      main: {
        program: {
          phases: [
            {
              trigger: 'direction-change',
              repetitions: 10,
              speed: 90,
            },
          ],
          maxBackwardForceLimit: 5,
        },
        parameters: {
          triggeringType: {
            default: 'uni-directional',
            unit: 'none',
            values: ['uni-directional', 'bi-directional'],
            blockAfterStart: true,
          },
          phases: [
            {
              movementType: {
                default: 'normal',
                unit: 'none',
                values: ['reverse', 'normal'],
                blockAfterStart: true,
              },
              triggeringMethod: {
                default: 'triggerAndRelease',
                unit: 'none',
                values: ['triggerAndRelease', 'triggerAndHold'],
                blockAfterStart: false,
              },
              repetitions: {
                default: 10,
                unit: 'number',
                values: [5, 10, 15, 20, 50, 100],
                blockAfterStart: false,
              },
              speed: {
                default: 90,
                unit: 'deg/s',
                values: [5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120],
                blockAfterStart: false,
              },
              pauseTimeInROMMin: {
                default: 0,
                unit: 's',
                values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 60],
                blockAfterStart: false,
              },
              pauseTimeInROMMax: {
                default: 0,
                unit: 's',
                values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 60],
                blockAfterStart: false,
              },
            },
          ],
          maxBackwardForceLimit: {
            default: 5,
            unit: 'kg',
            values: [1, 2, 3, 4, 5, 6, 7, 8, 9],
            blockAfterStart: false,
          },
        },
      },
    },
  },
});
