import { CalibrationController, ExoElectrostimFeature } from '@egzotech/exo-session/features/electrostim';
import { Logger } from '@egzotech/universal-logger-js';
import { Signal, signal } from 'helpers/signal';

export interface PortIndex {
  channelNumber: number;
}

export interface PortIntensity {
  intensity: number;
}

export interface EMSCalibrationData {
  active: boolean;
  ports: PortIndex[];
  calibration: PortIntensity[];
  selectedPort: number;
  calibrated: boolean;
}

export interface EMSCalibrationContext extends EMSCalibrationData {
  start: (channels: number[]) => void;
  end: () => void;
  resume: () => void;
  selectPort: (port: number) => void;
  setIntensity: (intensity: number) => void;
}

export default class EMSCalibration {
  private readonly PORT_LIMIT_INTENSITY = 100;
  private _emsFeature: ExoElectrostimFeature | null = null;
  private _calibration: CalibrationController | null = null;
  private _calibrationData: Signal<EMSCalibrationData> = signal(
    {
      active: false,
      calibrated: false,
      calibration: [],
      ports: [],
      selectedPort: 0,
    },
    'EMSCalibration._calibrationData',
  );

  static readonly logger = Logger.getInstance('EMSCalibration');

  constructor() {
    this._calibrationData.value = {
      ...this._calibrationData.peek(),
      active: false,
      calibrated: false,
      calibration: [],
      ports: [],
      selectedPort: 0,
    };
  }

  get data() {
    return this._calibrationData;
  }

  setFeature(emsFeature: ExoElectrostimFeature) {
    this._emsFeature = emsFeature;
  }

  start(channels: number[]) {
    if (this._calibrationData.peek().active) {
      EMSCalibration.logger.debug('start', 'Calibration already started');
      return;
    }
    const portsIndexes: PortIndex[] = [];
    const portsIntensity: PortIntensity[] = [];

    channels.forEach(channelNumber => {
      portsIndexes.push({ channelNumber });
      portsIntensity.push({ intensity: 0 });
    });

    this._calibrationData.value = {
      ...this._calibrationData.peek(),
      active: true,
      ports: portsIndexes,
    };

    this.calibrate();

    this._calibrationData.value = {
      ...this._calibrationData.peek(),
      calibration: portsIntensity,
    };
  }

  end() {
    if (!this._calibrationData.peek().active) {
      EMSCalibration.logger.debug('stop', 'Calibration is not active');
      return;
    }
    if (!this._calibration) {
      throw new Error('Calibration is not started');
    }
    this._calibration.end();
    this._calibration = null;
    this._calibrationData.value = {
      ...this._calibrationData.peek(),
      calibrated: true,
      active: false,
    };
  }

  resume(channels?: number[]) {
    const currentCalibrationData = this._calibrationData.peek();
    const portsIndexes: PortIndex[] = channels ? [] : currentCalibrationData.ports;
    const portsIntensity: PortIntensity[] = channels ? [] : currentCalibrationData.calibration;

    if (channels) {
      channels.forEach(channelNumber => {
        portsIndexes.push({ channelNumber });

        const index = currentCalibrationData.ports.findIndex(v => v.channelNumber === channelNumber);
        const intensity = index < 0 ? 0 : currentCalibrationData.calibration[index].intensity;
        portsIntensity.push({ intensity });
      });
    }

    this._calibrationData.value = {
      ...currentCalibrationData,
      active: true,
      ports: portsIndexes,
      calibration: portsIntensity,
    };
    this.calibrate();
  }

  selectPort(port: number) {
    if (
      !this._calibrationData.peek().active ||
      !this._calibrationData.peek().ports ||
      !this._calibrationData.peek().calibration
    ) {
      EMSCalibration.logger.debug('selectPort', 'Calibration is not active');
      return;
    }
    if (!this._calibration) {
      EMSCalibration.logger.debug('selectPort', 'Calibration is not started');
      return;
    }
    if (!(port >= 0 && port < this._calibrationData.peek().ports.length)) {
      EMSCalibration.logger.debug('selectPort', 'Selected port is invalid');
      return;
    }

    this._calibration.setIntensity(
      this._calibrationData.peek().ports[port].channelNumber,
      this._calibrationData.peek().calibration[port].intensity / 1000,
    );

    this._calibrationData.value = {
      ...this._calibrationData.peek(),
      selectedPort: port,
    };
  }

  setIntensity(intensity: number) {
    const { active, ports, calibration, selectedPort } = this._calibrationData.peek();
    if (!active || !ports || !calibration) {
      EMSCalibration.logger.debug('setIntensity', 'Calibration is not active');
      return;
    }
    if (!this._calibration) {
      EMSCalibration.logger.debug('setIntensity', 'Calibration is not started');
      return;
    }
    if (intensity < 0) {
      intensity = 0;
    }
    if (intensity > this.PORT_LIMIT_INTENSITY) {
      intensity = this.PORT_LIMIT_INTENSITY;
    }

    calibration[selectedPort].intensity = intensity;
    this._calibration.setIntensity(ports[selectedPort].channelNumber, intensity / 1000);

    this._calibrationData.value = {
      ...this._calibrationData.peek(),
      calibration: this._calibrationData.peek().calibration.map((portIntensity, port) =>
        port === selectedPort
          ? {
              intensity: intensity,
            }
          : portIntensity,
      ),
    };
  }

  storeCalibrationData() {
    if (!this._calibration) {
      EMSCalibration.logger.debug('storeCalibrationData', 'Calibration is not started');
      return;
    }
    this._calibration.store();
  }

  restoreCalibrationData() {
    if (!this._calibration) {
      EMSCalibration.logger.debug('restoreCalibrationData', 'Calibration is not started');
      return;
    }
    const restoredData = this._calibration.restore();
    if (restoredData) {
      const newCalibrationData = this._calibrationData.peek().ports.map((value, idx) => {
        return {
          intensity:
            restoredData[value.channelNumber] !== undefined
              ? restoredData[value.channelNumber] * 1e3
              : this._calibrationData.peek().calibration[idx].intensity,
        };
      });

      this._calibrationData.value = {
        ...this._calibrationData.peek(),
        calibration: newCalibrationData,
      };
    }
  }

  private calibrate() {
    if (!this._calibrationData.peek().active || !this._calibrationData.peek().ports) {
      EMSCalibration.logger.debug('calibrate', 'Calibration is not active');
      return;
    }
    if (this._calibration) {
      EMSCalibration.logger.debug('calibrate', 'Calibration is already in progress');
      return;
    }
    if (!this._emsFeature) {
      throw new Error('Cannot calibrate EMS, when feature is not active');
    }

    const program = this._emsFeature.program;

    if (!program.isCableCorrect()) {
      throw new Error('Cable is not correct');
    }

    const mappedChannels: number[] = [];

    if (program.minRequiredChannels() === 1) {
      this._calibrationData.peek().ports.forEach(port => {
        mappedChannels[port.channelNumber] = 0;
      });
    } else if (program.minRequiredChannels() > 1 && program.minRequiredChannels() === program.maxSupportedChannels()) {
      Object.entries(this._calibrationData.peek().ports).forEach(([key, port]) => {
        mappedChannels[port.channelNumber] = +key;
      });
    }

    this._emsFeature.setChannelMapping(mappedChannels);

    if (!program.isChannelMappingValid()) {
      throw new Error('Incorrect channel mapping');
    }

    this._calibration = this._emsFeature.calibrate();
  }
}
