import { useCallback, useState } from 'react';
import { Container, Flex, HStack, ModalBody, ModalContent, Spinner, VStack } from '@chakra-ui/react';
import { ExoSensorForceFeature } from '@egzotech/exo-session/features/sensor-force';
import { PEC_SUCCESS_THRESHOLD } from 'containers/common/MenuSettings/PECCalibration';
import { logger } from 'helpers/logger';
import { DeviceManager } from 'libs/exo-session-manager/core';
import { useDevice } from 'libs/exo-session-manager/react';

import { MainButton } from 'components/buttons/MainButton';
import { CheckIcon, ExclamationIcon } from 'components/icons';
import { MainHeading } from 'components/texts/MainHeading';
import { TranslateText } from 'components/texts/TranslateText';

import { ModalProps } from './Modal';

const ModalStack = ({ children }: { children: React.ReactNode }) => {
  return (
    <VStack
      gap={{ base: '6', '2xl': '8' }}
      justifyContent="space-between"
      py={4}
      textAlign="center"
      data-testid="pecCalibration-content"
    >
      {children}
    </VStack>
  );
};

export const useCheckExtensionPresence = () => {
  const { selectedDevice } = useDevice();

  return Boolean(selectedDevice?.extensionType);
};

interface QuestionStepProps {
  isExtensionPresent: boolean;
  onCalibrate: () => void;
  onCancel: () => void;
}

const QuestionStep = ({ isExtensionPresent, onCalibrate, onCancel }: QuestionStepProps) => {
  return (
    <ModalStack>
      <MainHeading
        variant="subheading36"
        text={'pecCalibration.question.header'}
        textAlign="center"
        data-testid="pecCalibration-question-header"
      />
      <TranslateText as="span" text="pecCalibration.question.par1" />
      <TranslateText>
        <TranslateText as="span" text="pecCalibration.question.par2-1" />
        <TranslateText as="span" fontWeight="bold" text="pecCalibration.question.par2-2" />
        <TranslateText as="span" text="pecCalibration.question.par2-3" />
        <TranslateText as="span" fontWeight="bold" text="pecCalibration.question.par2-4" />
        <TranslateText as="span" text="pecCalibration.question.par2-5" />
      </TranslateText>
      <HStack w="full" justifyContent="space-between" px="8">
        <MainButton
          text="common.cancel"
          variant="mdOutlinedPrimaryButton"
          onClick={onCancel}
          minW="32"
          data-testid="pecCalibration-cancel-button"
        />
        <MainButton
          text="pecCalibration.buttonText"
          variant="mdPrimaryButton"
          onClick={onCalibrate}
          isDisabled={isExtensionPresent}
          minW="32"
          data-testid="pecCalibration-calibrate-button"
        />
      </HStack>
    </ModalStack>
  );
};

interface CalibrationStepProps {
  currentStatus: DeviceCalibrationStep;
  onClose: () => void;
}

const CalibrationStep = ({ currentStatus, onClose }: CalibrationStepProps) => {
  return (
    <ModalStack>
      <MainHeading
        variant="subheading36"
        text={'pecCalibration.calibration.header'}
        textAlign="center"
        data-testid="pecCalibration-calibration-header"
      />
      {currentStatus === 'inprogress' ? (
        <Flex width="100%" justifyContent="center" alignItems="center" pt="6" pb="6" h={28}>
          <Spinner thickness="1" speed="1s" emptyColor="white" mr="2" color="egzotechPrimaryColor" boxSize="5" />
          <TranslateText
            color="egzotechPrimaryColor"
            variant="openSans24"
            fontWeight="bold"
            as="span"
            text="pecCalibration.calibration.progress"
          />
        </Flex>
      ) : currentStatus === 'success' ? (
        <VStack pt="6" pb="6" h={28}>
          <Flex width="100%" justifyContent="center" alignItems="center">
            <CheckIcon
              bgColor="stellaBioPrimaryColor"
              color="white"
              borderRadius="full"
              p="1"
              fontWeight="bold"
              width={7}
              height={7}
              mr="2"
            />
            <TranslateText
              variant="openSans24Bold"
              text="pecCalibration.calibration.success"
              color="stellaBioPrimaryColor"
            />
          </Flex>
          {DeviceManager.pecStandardDeviation.value && (
            <TranslateText variant="openSans16Bold" color="gray.400" text="pec.result">
              {DeviceManager.pecStandardDeviation.value.toFixed(3)}
            </TranslateText>
          )}
        </VStack>
      ) : currentStatus === 'fatal' ? (
        <VStack>
          <Flex width="100%" justifyContent="center" alignItems="center" pt="6" pb="6" h={28}>
            <ExclamationIcon
              bgColor="errorMediumColor"
              color="white"
              borderRadius="full"
              p="1"
              boxSizing="content-box"
              fontWeight="bold"
              mr="2"
            />
            <TranslateText
              as="span"
              variant="openSans24"
              color="errorMediumColor"
              fontWeight="bold"
              text="pecCalibration.calibration.error"
            />
          </Flex>
          <TranslateText variant="openSans16" color="textColor" text="pecCalibration.calibration.fatal" />
        </VStack>
      ) : (
        <VStack pt="6" pb="6" h={28}>
          <Flex width="100%" justifyContent="center" alignItems="center">
            <ExclamationIcon
              bgColor="errorMediumColor"
              color="white"
              borderRadius="full"
              p="1"
              boxSizing="content-box"
              fontWeight="bold"
              mr="2"
            />
            <TranslateText
              variant="openSans24"
              color="errorMediumColor"
              fontWeight="bold"
              text="pecCalibration.calibration.error"
            />
          </Flex>
          {DeviceManager.pecStandardDeviation.value && (
            <TranslateText variant="openSans16Bold" color="gray.400" text="pec.result">
              {DeviceManager.pecStandardDeviation.value.toFixed(3)}
            </TranslateText>
          )}
        </VStack>
      )}
      {currentStatus !== 'fatal' && (
        <MainButton
          text="common.close"
          variant="mdPrimaryButton"
          isDisabled={currentStatus === 'inprogress'}
          minW="32"
          onClick={onClose}
        />
      )}
    </ModalStack>
  );
};

export type DeviceCalibrationStep = 'initial' | 'inprogress' | 'success' | 'error' | 'fatal';

export const PECCalibrationModal = ({ close }: ModalProps) => {
  const [currentStep, setCurrentStep] = useState<DeviceCalibrationStep>('initial');
  const isExtensionPresent = useCheckExtensionPresence();
  const { session } = useDevice();

  const setStep = useCallback((status: DeviceCalibrationStep) => {
    setCurrentStep(status);
  }, []);

  const runCalibration = useCallback(async () => {
    const torqueSensorFeature = session?.activate(ExoSensorForceFeature, { name: 'torque' });

    if (!torqueSensorFeature) {
      throw new Error('Cannot get ExoSensorForceFeature for torque sensor which is required to do PEC Calibration');
    }

    try {
      await torqueSensorFeature.calibratePEC();
    } catch (error) {
      logger.warn('PECCalibrationModal', 'Error occurred during PEC calibration: ', error);
      torqueSensorFeature.dispose();
      setStep('fatal');
      return;
    }
    // we have to wait to retrieve the most recent pec stddev after calibration
    setTimeout(async () => {
      // We need to tare the device after PEC calibration because force sensor readings are
      // related to the value of standard deviation.
      torqueSensorFeature.tare();

      const result = await torqueSensorFeature.getPECStandardDeviation();
      DeviceManager.pecStandardDeviation.value = result;
      if (result < PEC_SUCCESS_THRESHOLD && result > 0) {
        setStep('success');
      } else {
        logger.warn(
          'PECCalibrationModal',
          `PEC standard deviation (${result}) is greater then accepted threshold (${PEC_SUCCESS_THRESHOLD}). Device should be sent to EGZOTech Service`,
        );
        setStep('error');
      }
      torqueSensorFeature.dispose();
    }, 500);
  }, [session, setStep]);

  const getStep = () => {
    switch (currentStep) {
      case 'initial':
        return (
          <QuestionStep
            isExtensionPresent={isExtensionPresent}
            onCancel={() => {
              close();
            }}
            onCalibrate={() => {
              setStep('inprogress');
              runCalibration();
            }}
          />
        );
      default:
        return (
          <CalibrationStep
            currentStatus={currentStep}
            onClose={() => {
              close();
              setStep('initial');
            }}
          />
        );
    }
  };

  return (
    <ModalContent maxW={{ base: 'lg', '2xl': '2xl' }} borderRadius="rMd" p="3">
      <ModalBody>
        <Container variant="modalBox" px={0}>
          {getStep()}
        </Container>
      </ModalBody>
    </ModalContent>
  );
};
