import { ChannelConnectionQuality } from '@egzotech/exo-session/features/cable';
import {
  ExerciseTimelineEntry,
  isExerciseTimelineEntryCAMParameterChange,
  isExerciseTimelineEntryCPMParameterChange,
  isExerciseTimelineEntryEmgParameterChange,
  isExerciseTimelineEntryEmsParameterChange,
  isExerciseTimelineEntryGameParameterChange,
} from 'slices/trainingReportSlice';

import { SensorsName } from '../types';

import { HistoryAction, HistoryActionTracker } from './HistoryActionTracker';

export interface ExerciseTimePoints {
  basing: { start?: Date; end?: Date };
  exercise: { start?: Date; end?: Date };
}

export function isNotNull<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export class ExerciseActionTracker extends HistoryActionTracker {
  private _timeline: ExerciseTimelineEntry[] = [];

  activeChannels?: number[];

  timePoints: ExerciseTimePoints = {
    basing: {},
    exercise: {},
  };

  get timeline() {
    return this._timeline;
  }

  getBasingActions(category?: HistoryAction['category']) {
    if (!this.timePoints.basing?.start || !this.timePoints.basing?.end) {
      throw new Error('Cannot get basing actions, because they are not recorded');
    }
    if (category) {
      return this.getCategorizedActions(category)(
        this.getActionsInTimeRange(this.timePoints.basing.start, this.timePoints.basing.end),
      );
    }
    return this.getActionsInTimeRange(this.timePoints.basing.start, this.timePoints.basing.end);
  }

  getExerciseActions(category: HistoryAction['category']): {
    count: number;
    actions: HistoryAction[];
  };
  getExerciseActions(): HistoryAction[];
  getExerciseActions(category?: HistoryAction['category']) {
    if (!this.timePoints.exercise?.start || !this.timePoints.exercise?.end) {
      throw new Error('Cannot get exercise actions, because they are not recorded');
    }
    if (category) {
      return this.getCategorizedActions(category)(
        this.getActionsInTimeRange(this.timePoints.exercise.start, this.timePoints.exercise.end),
      );
    }
    return this.getActionsInTimeRange(this.timePoints.exercise.start, this.timePoints.exercise.end);
  }

  extractExerciseActionsForTimeline() {
    if (!this.timePoints.basing?.start || !this.timePoints.exercise?.end) {
      throw new Error('Cannot clear exercise actions, because they are not recorded');
    }

    const actions = this.clearActionsInTimeRange(this.timePoints.basing.start, this.timePoints.exercise.end);
    this._timeline = this.generateTimeline(actions);

    this.timePoints = {
      basing: {},
      exercise: {},
    };

    return actions;
  }

  generateTimeline(actions = this._actions) {
    return actions
      .map<ExerciseTimelineEntry | null>(action => {
        if (action.name === 'play') {
          const delta = action.timestamp.getTime() - (this.timePoints.exercise?.start?.getTime() ?? 0);
          return {
            type: action.name,
            time: delta,
            description: `trainingReport.exerciseTimeline.events.${delta > 0 ? 'resume' : action.name}`,
          };
        }
        if (action.name === 'pause') {
          return {
            type: action.name,
            time: action.timestamp.getTime() - (this.timePoints.exercise?.start?.getTime() ?? 0),
            description: `trainingReport.exerciseTimeline.events.${action.name}`,
            duration: this.getTimeBetweenTwoActions(action.name, 'play', { withIncrementedSecondActionNumber: 1 })(
              actions,
            )[action.actionNumber],
          };
        }
        if (action.name === 'stop') {
          return {
            type: action.name,
            time: action.timestamp.getTime() - (this.timePoints.exercise?.start?.getTime() ?? 0),
            description: `trainingReport.exerciseTimeline.events.${action.name}`,
            reason: action.params!.reason,
          };
        }
        if (action.name === 'spasticism-active' || action.name === 'spasticism-inactive') {
          const error = action?.params && 'error' in action.params ? action.params.error : undefined;
          return {
            type: action.name,
            time: action.timestamp.getTime() - (this.timePoints.exercise?.start?.getTime() ?? 0),
            description: `trainingReport.exerciseTimeline.events.${action.name}`,
            error,
          };
        }
        if (action.name === 'increase-force-threshold' || action.name === 'decrease-force-threshold') {
          const descriptionBySensor: Partial<
            Record<
              SensorsName,
              {
                min: string;
                max: string;
              }
            >
          > = {
            knee: {
              min: 'trainingReport.forceThresholdChange.toMin',
              max: 'trainingReport.forceThresholdChange.toMax',
            },
            heel: {
              min: 'trainingReport.forceThresholdChange.forPlantarFlexion',
              max: 'trainingReport.forceThresholdChange.forDorsiflexion',
            },
            toes: {
              min: 'trainingReport.forceThresholdChange.forPlantarFlexion',
              max: 'trainingReport.forceThresholdChange.forDorsiflexion',
            },
            torque: {
              min: 'trainingReport.forceThresholdChange.toCounterClockwise',
              max: 'trainingReport.forceThresholdChange.toClockwise',
            },
            extension: {
              min: '',
              max: '',
            },
          };

          const sensorName = action.params?.sensorName;

          const paramDescription = (
            sensorName
              ? action.params?.isNegative
                ? descriptionBySensor[sensorName]?.min
                : descriptionBySensor[sensorName]?.max
              : ''
          ) as string;

          return {
            ...action,
            from: action.params?.from ?? 0,
            to: action.params?.to ?? 0,
            isNegative: action.params?.isNegative ?? false,
            unit: '%',
            type: action.name,
            time: action.timestamp.getTime() - (this.timePoints.exercise?.start?.getTime() ?? 0),
            description: `trainingReport.exerciseTimeline.events.${action.name}`,
            paramDescription: paramDescription,
          };
        }
        if (
          action.params &&
          (isExerciseTimelineEntryCPMParameterChange(action.params) ||
            isExerciseTimelineEntryEmsParameterChange(action.params) ||
            isExerciseTimelineEntryEmgParameterChange(action.params) ||
            isExerciseTimelineEntryCAMParameterChange(action.params) ||
            isExerciseTimelineEntryGameParameterChange(action.params))
        ) {
          return {
            ...action.params,
            time: action.params?.time - (this.timePoints.exercise?.start?.getTime() ?? 0),
          };
        }
        if (action.name === 'cable-attached' || action.name === 'cable-detached') {
          const cable = action?.params && 'cable' in action.params ? action.params.cable : undefined;
          return {
            type: action.name,
            time: action.timestamp.getTime() - (this.timePoints.exercise?.start?.getTime() ?? 0),
            description: `trainingReport.exerciseTimeline.events.${action.name}`,
            cableId: cable?.id,
            cableDescription: cable?.description,
          };
        }
        if (action.name === 'extension-attached' || action.name === 'extension-detached') {
          const extensionType = action?.params && 'extension' in action.params ? action.params.extension.type : null;
          return {
            type: action.name,
            time: action.timestamp.getTime() - (this.timePoints.exercise?.start?.getTime() ?? 0),
            description: `trainingReport.exerciseTimeline.events.${action.name}`,
            extensionType: extensionType,
          };
        }
        if (
          this.activeChannels &&
          action.name === 'channel-quality-change' &&
          this.timePoints.exercise?.start &&
          action.timestamp.getTime() >= this.timePoints.exercise.start.getTime()
        ) {
          //FIXME: something is wrong with channels detach/attach in real devices, needs more investigation, we have to disable it for 0.8.0 release
          const disabled = true;
          if (disabled) {
            return null;
          }
          const channels = action?.params && 'channels' in action.params ? action.params.channels : {};
          const detachedChannels = Object.entries(channels)
            .filter(
              ([k, v]) =>
                v === ChannelConnectionQuality.NONE && this.activeChannels?.findIndex(channel => +k === channel) !== -1,
            )
            .reduce((acc, [k]) => {
              acc[+k] = channels[+k];
              return acc;
            }, {} as typeof channels);
          // Track detached channels
          if (Object.keys(detachedChannels).length > 0) {
            return {
              type: action.name,
              time: action.timestamp.getTime() - (this.timePoints.exercise?.start?.getTime() ?? 0),
              description: `trainingReport.exerciseTimeline.events.${action.name}.toNone`,
              channels: detachedChannels,
            };
          }
          // If one of the channel has been detached during exercise then track attached channels
          if (
            actions.find(
              action =>
                action.name === 'channel-quality-change' &&
                this.timePoints.exercise?.start &&
                action.timestamp.getTime() >= this.timePoints.exercise?.start?.getTime() &&
                Object.entries(action?.params && 'channels' in action.params ? action.params.channels : {}).some(
                  ([k, v]) =>
                    v === ChannelConnectionQuality.NONE &&
                    this.activeChannels?.findIndex(channel => +k === channel) !== -1,
                ),
            )
          ) {
            const attachedChannels = Object.entries(channels)
              .filter(
                ([k, v]) =>
                  v === ChannelConnectionQuality.WELL &&
                  this.activeChannels?.findIndex(channel => +k === channel) !== -1,
              )
              .reduce((acc, [k]) => {
                acc[+k] = channels[+k];
                return acc;
              }, {} as typeof channels);
            if (Object.keys(attachedChannels).length > 0) {
              return {
                type: action.name,
                time: action.timestamp.getTime() - (this.timePoints.exercise?.start?.getTime() ?? 0),
                description: `trainingReport.exerciseTimeline.events.${action.name}.toWell`,
                channels: attachedChannels,
              };
            }
          }
        }
        if (action.name === 'session-error') {
          const error = action?.params && 'error' in action.params ? action.params.error : undefined;
          return {
            type: action.name,
            time: action.timestamp.getTime() - (this.timePoints.exercise?.start?.getTime() ?? 0),
            description: `trainingReport.exerciseTimeline.events.${action.name}`,
            error,
          };
        }
        return null;
      })
      .filter<ExerciseTimelineEntry>(isNotNull);
  }
}
