import { GeneratedCPMExerciseDefinition } from '../../types/GeneratedExerciseDefinition';

export const getPredefinedSidraLegCPMEMSExercises = (): { [key: string]: GeneratedCPMExerciseDefinition } => ({
  cpm_ems_classic_knee: {
    type: 'cpm-ems',
    calibrationFlow: 'cpm-ems/knee',
    tags: ['ems'],
    cpm: {
      knee: {
        program: {
          phases: [
            {
              repetitions: 10,
              speed: 10,
              trigger: 'direction-change',
            },
          ],
          maxBackwardForceLimit: 5,
        },
        parameters: {
          triggeringType: {
            default: 'uni-directional',
            unit: 'none',
            values: ['uni-directional', 'bi-directional'],
            blockAfterStart: true,
          },
          phases: [
            {
              movementType: {
                default: 'normal',
                unit: 'none',
                values: ['reverse', 'normal'],
                blockAfterStart: true,
              },
              repetitions: {
                default: 10,
                unit: 'number',
                values: [5, 10, 15, 20, 50, 100],
                blockAfterStart: false,
              },
              speed: {
                default: 10,
                unit: 'deg/s',
                values: [0.2, 1, 2, 5, 10, 15, 20],
                blockAfterStart: false,
              },
              pauseTimeInROMMin: {
                default: 0,
                unit: 's',
                values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 60],
                blockAfterStart: false,
              },
              pauseTimeInROMMax: {
                default: 0,
                unit: 's',
                values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 60],
                blockAfterStart: false,
              },
            },
          ],
          maxBackwardForceLimit: {
            default: 5,
            unit: 'kg',
            values: [
              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
              30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
              56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81,
              82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
            ],
            blockAfterStart: false,
          },
        },
      },
      ankle: {
        program: null,
      },
    },
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 50,
            pulseShape: 100,
            pulseWidth: 250,
            riseTime: 1000000,
            runTime: 1800000000,
            plateauTime: 1000000,
            pauseTime: 1000000,
          },
        ],
        maxIntensity: [0.1],
        phases: [
          {
            needsTrigger: true,
            channels: [],
          },
        ],
        phasesRepetition: 1,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 50,
            pulseShape: 100,
            pulseWidth: 250,
            riseTime: 1000000,
            runTime: 5000000,
            plateauTime: 1000000,
            pauseTime: 1000000,
          },
        ],
        programTime: 1800000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        tags: ['ems'],
        allowedCableChannelFeatures: ['ems'],
      },
      parameters: {
        phases: [
          {
            pulseFrequency: {
              unit: 'Hz',
              default: 50,
              values: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100],
              blockAfterStart: false,
            },
            pulseWidth: {
              unit: 'us',
              default: 250,
              values: [50, 100, 150, 200, 250, 300, 350, 400, 450, 500],
              blockAfterStart: false,
            },
            riseTime: {
              unit: 'us',
              default: 1000000,
              values: [0, 1000000, 2000000, 3000000, 4000000],
              blockAfterStart: false,
            },
            plateauTime: {
              unit: 'us',
              default: 1000000,
              values: [
                1000000, 2000000, 3000000, 4000000, 5000000, 6000000, 7000000, 8000000, 9000000, 10000000, 11000000,
                12000000, 13000000, 14000000, 15000000, 16000000, 17000000, 18000000, 19000000, 20000000,
              ],
              blockAfterStart: false,
            },
            fallTime: {
              unit: 'us',
              default: 1000000,
              values: [0, 1000000, 2000000, 3000000, 4000000],
              blockAfterStart: false,
            },
          },
        ],
      },
    },
  },
  cpm_ems_classic_knee_synced: {
    type: 'cpm-ems',
    calibrationFlow: 'cpm-ems/knee-synced',
    tags: ['ems'],
    cpm: {
      ankle: {
        program: {
          phases: [
            {
              repetitions: 10,
              speed: 30,
              trigger: 'direction-change',
            },
          ],
          maxBackwardForceLimit: 5,
        },
        parameters: {
          triggeringType: {
            default: 'uni-directional',
            unit: 'none',
            values: ['uni-directional', 'bi-directional'],
            blockAfterStart: true,
          },
          phases: [
            {
              movementType: {
                default: 'normal',
                unit: 'none',
                values: ['reverse', 'normal'],
                blockAfterStart: true,
              },
              repetitions: {
                default: 10,
                unit: 'number',
                values: [5, 10, 15, 20, 50, 100],
                blockAfterStart: false,
              },
              speed: {
                default: 30,
                unit: 'deg/s',
                values: [0.2, 1, 2, 5, 10, 15, 20, 25, 30, 40, 50, 60],
                blockAfterStart: false,
              },
              pauseTimeInROMMin: {
                default: 0,
                unit: 's',
                values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 60],
                blockAfterStart: false,
              },
              pauseTimeInROMMax: {
                default: 0,
                unit: 's',
                values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 60],
                blockAfterStart: false,
              },
            },
          ],
        },
      },
      knee: {
        program: null,
        synchronized: 'ankle',
      },
    },
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 50,
            pulseShape: 100,
            pulseWidth: 250,
            riseTime: 1000000,
            runTime: 1800000000,
            plateauTime: 1000000,
            pauseTime: 1000000,
          },
        ],
        maxIntensity: [0.1],
        phases: [
          {
            needsTrigger: true,
            channels: [],
          },
        ],
        phasesRepetition: 1,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 50,
            pulseShape: 100,
            pulseWidth: 250,
            riseTime: 1000000,
            runTime: 5000000,
            plateauTime: 1000000,
            pauseTime: 1000000,
          },
        ],
        programTime: 1800000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        tags: ['ems'],
        allowedCableChannelFeatures: ['ems'],
      },
      parameters: {
        phases: [
          {
            pulseFrequency: {
              unit: 'Hz',
              default: 50,
              values: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100],
              blockAfterStart: false,
            },
            pulseWidth: {
              unit: 'us',
              default: 250,
              values: [50, 100, 150, 200, 250, 300, 350, 400, 450, 500],
              blockAfterStart: false,
            },
            riseTime: {
              unit: 'us',
              default: 1000000,
              values: [0, 1000000, 2000000, 3000000, 4000000],
              blockAfterStart: false,
            },
            plateauTime: {
              unit: 'us',
              default: 1000000,
              values: [
                1000000, 2000000, 3000000, 4000000, 5000000, 6000000, 7000000, 8000000, 9000000, 10000000, 11000000,
                12000000, 13000000, 14000000, 15000000, 16000000, 17000000, 18000000, 19000000, 20000000,
              ],
              blockAfterStart: false,
            },
            fallTime: {
              unit: 'us',
              default: 1000000,
              values: [0, 1000000, 2000000, 3000000, 4000000],
              blockAfterStart: false,
            },
          },
        ],
      },
    },
  },
  cpm_ems_classic_ankle: {
    type: 'cpm-ems',
    calibrationFlow: 'cpm-ems/ankle',
    tags: ['ems'],
    cpm: {
      ankle: {
        program: {
          phases: [
            {
              repetitions: 10,
              speed: 30,
              trigger: 'direction-change',
            },
          ],
        },
        parameters: {
          triggeringType: {
            default: 'uni-directional',
            unit: 'none',
            values: ['uni-directional', 'bi-directional'],
            blockAfterStart: true,
          },
          phases: [
            {
              movementType: {
                default: 'normal',
                unit: 'none',
                values: ['reverse', 'normal'],
                blockAfterStart: true,
              },
              repetitions: {
                default: 10,
                unit: 'number',
                values: [5, 10, 15, 20, 50, 100],
                blockAfterStart: false,
              },
              speed: {
                default: 30,
                unit: 'deg/s',
                values: [0.2, 1, 2, 3, 4, 5, 6, 8, 10, 12, 14, 16, 18, 20, 25, 30, 35, 40, 45, 50, 55, 60],
                blockAfterStart: false,
              },
              pauseTimeInROMMax: {
                default: 0,
                unit: 's',
                values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 60],
                blockAfterStart: false,
              },
              pauseTimeInROMMin: {
                default: 0,
                unit: 's',
                values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 60],
                blockAfterStart: false,
              },
            },
          ],
          maxBackwardForceLimit: {
            default: 5,
            unit: 'kg',
            values: [
              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
              30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
              56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81,
              82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
            ],
            blockAfterStart: false,
          },
        },
      },
      knee: {
        program: null,
      },
    },
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 50,
            pulseShape: 100,
            pulseWidth: 250,
            riseTime: 1000000,
            runTime: 1800000000,
            plateauTime: 1000000,
            pauseTime: 1000000,
          },
        ],
        maxIntensity: [0.1],
        phases: [
          {
            needsTrigger: true,
            channels: [],
          },
        ],
        phasesRepetition: 1,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 50,
            pulseShape: 100,
            pulseWidth: 250,
            riseTime: 1000000,
            runTime: 5000000,
            plateauTime: 1000000,
            pauseTime: 1000000,
          },
        ],
        programTime: 1800000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        tags: ['ems'],
        allowedCableChannelFeatures: ['ems'],
      },
      parameters: {
        phases: [
          {
            pulseFrequency: {
              unit: 'Hz',
              default: 50,
              values: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100],
              blockAfterStart: false,
            },
            pulseWidth: {
              unit: 'us',
              default: 250,
              values: [50, 100, 150, 200, 250, 300, 350, 400, 450, 500],
              blockAfterStart: false,
            },
            riseTime: {
              unit: 'us',
              default: 1000000,
              values: [0, 1000000, 2000000, 3000000, 4000000],
              blockAfterStart: false,
            },
            plateauTime: {
              unit: 'us',
              default: 1000000,
              values: [
                1000000, 2000000, 3000000, 4000000, 5000000, 6000000, 7000000, 8000000, 9000000, 10000000, 11000000,
                12000000, 13000000, 14000000, 15000000, 16000000, 17000000, 18000000, 19000000, 20000000,
              ],
              blockAfterStart: false,
            },
            fallTime: {
              unit: 'us',
              default: 1000000,
              values: [0, 1000000, 2000000, 3000000, 4000000],
              blockAfterStart: false,
            },
          },
        ],
      },
    },
  },
  cpm_ems_classic_ankle_synced: {
    type: 'cpm-ems',
    calibrationFlow: 'cpm-ems/ankle-synced',
    tags: ['ems'],
    cpm: {
      knee: {
        program: {
          phases: [
            {
              repetitions: 10,
              speed: 10,
              trigger: 'direction-change',
            },
          ],
          maxBackwardForceLimit: 5,
        },
        parameters: {
          triggeringType: {
            default: 'uni-directional',
            unit: 'none',
            values: ['uni-directional', 'bi-directional'],
            blockAfterStart: true,
          },
          phases: [
            {
              movementType: {
                default: 'normal',
                unit: 'none',
                values: ['reverse', 'normal'],
                blockAfterStart: true,
              },
              repetitions: {
                default: 10,
                unit: 'number',
                values: [5, 10, 15, 20, 50, 100],
                blockAfterStart: false,
              },
              speed: {
                default: 10,
                unit: 'deg/s',
                values: [0.2, 1, 2, 5, 10, 15, 20],
                blockAfterStart: false,
              },
              pauseTimeInROMMin: {
                default: 0,
                unit: 's',
                values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 60],
                blockAfterStart: false,
              },
              pauseTimeInROMMax: {
                default: 0,
                unit: 's',
                values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 60],
                blockAfterStart: false,
              },
            },
          ],
          maxBackwardForceLimit: {
            default: 5,
            unit: 'kg',
            values: [
              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
              30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
              56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81,
              82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
            ],
            blockAfterStart: false,
          },
        },
      },
      ankle: {
        program: null,
        synchronized: 'knee',
      },
    },
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 50,
            pulseShape: 100,
            pulseWidth: 250,
            riseTime: 1000000,
            runTime: 1800000000,
            plateauTime: 1000000,
            pauseTime: 1000000,
          },
        ],
        maxIntensity: [0.1],
        phases: [
          {
            needsTrigger: true,
            channels: [],
          },
        ],
        phasesRepetition: 1,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 50,
            pulseShape: 100,
            pulseWidth: 250,
            riseTime: 1000000,
            runTime: 5000000,
            plateauTime: 1000000,
            pauseTime: 1000000,
          },
        ],
        programTime: 1800000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        tags: ['ems'],
        allowedCableChannelFeatures: ['ems'],
      },
      parameters: {
        phases: [
          {
            pulseFrequency: {
              unit: 'Hz',
              default: 50,
              values: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100],
              blockAfterStart: false,
            },
            pulseWidth: {
              unit: 'us',
              default: 250,
              values: [50, 100, 150, 200, 250, 300, 350, 400, 450, 500],
              blockAfterStart: false,
            },
            riseTime: {
              unit: 'us',
              default: 1000000,
              values: [0, 1000000, 2000000, 3000000, 4000000],
              blockAfterStart: false,
            },
            plateauTime: {
              unit: 'us',
              default: 1000000,
              values: [
                1000000, 2000000, 3000000, 4000000, 5000000, 6000000, 7000000, 8000000, 9000000, 10000000, 11000000,
                12000000, 13000000, 14000000, 15000000, 16000000, 17000000, 18000000, 19000000, 20000000,
              ],
              blockAfterStart: false,
            },
            fallTime: {
              unit: 'us',
              default: 1000000,
              values: [0, 1000000, 2000000, 3000000, 4000000],
              blockAfterStart: false,
            },
          },
        ],
      },
    },
  },
});
